import React from "react"
import {Card} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import { injectIntl, Link } from "gatsby-plugin-react-intl";

function SimpleRowCard ({ intl, content }) {
    if (content.button) {
        if (content.button.target) {
            return (
                <div className={content.alignment ? `simple-row-card ` + content.alignment : `simple-row-card`}>
                    <Card>
                        <a className={`card-button`} href={content.button.url} target={content.button.target} rel={`noopener`}>
                            <Card.Body>
                                {content.title &&
                                <Card.Title>{content.title}</Card.Title>
                                }
                                {content.subtitle &&
                                <Card.Subtitle className="mb-2 text-muted">{content.subtitle}</Card.Subtitle>
                                }
                                {content.text &&
                                <Card.Text dangerouslySetInnerHTML={{__html: content.text}}></Card.Text>
                                }
                                {content.button &&
                                <div className={`button`}>{content.button.label} <FontAwesomeIcon icon="arrow-right" /></div>
                                }
                            </Card.Body>
                        </a>
                    </Card>
                </div>
            )
        } else {
            return (
                <div className={content.alignment ? `simple-row-card ` + content.alignment : `simple-row-card`}>
                    <Card>
                        <Link className={`card-button`} to={content.button.url}>
                            <Card.Body>
                                {content.title &&
                                <Card.Title>{content.title}</Card.Title>
                                }
                                {content.subtitle &&
                                <Card.Subtitle className="mb-2 text-muted">{content.subtitle}</Card.Subtitle>
                                }
                                {content.text &&
                                <Card.Text dangerouslySetInnerHTML={{__html: content.text}}></Card.Text>
                                }
                                {content.button &&
                                <div className={`button`}>{content.button.label} <FontAwesomeIcon icon="arrow-right" /></div>
                                }
                            </Card.Body>
                        </Link>
                    </Card>
                </div>
            )
        }
    } else {
       return(
           <div className={content.alignment ? `simple-row-card ` + content.alignment : `simple-row-card`}>
               <Card>
                   <Card.Body>
                       {content.title &&
                       <Card.Title>{content.title}</Card.Title>
                       }
                       {content.subtitle &&
                       <Card.Subtitle className="mb-2 text-muted">{content.subtitle}</Card.Subtitle>
                       }
                       {content.text &&
                       <Card.Text dangerouslySetInnerHTML={{__html: content.text}}></Card.Text>
                       }
                   </Card.Body>
               </Card>
           </div>
       )
    }
}

export default injectIntl(SimpleRowCard)
