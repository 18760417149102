import React from "react"

import LayoutSecondary from "../../components/layout-secondary"
import Seo from "../../components/seo"
import { injectIntl } from "gatsby-plugin-react-intl"
import HeroPages from "../../components/heroes/hero-pages";
import {Col, Container, Row} from "react-bootstrap";
import CareersData from "../../content/careers/index.json";
import SimpleRowCard from "../../components/misc/simple-row-card";
import ClientsTicker from "../../components/misc/clients-ticker";
import TickerData from "../../content/careers/ticker.json"

const CareersIndexPage = ({ intl, location }) => (
  <LayoutSecondary>
    <Seo
        lang={intl.locale}
        title={CareersData[intl.locale].title}
        description={CareersData[intl.locale].text}
        pathname={location.pathname}
    />
    <HeroPages
      theme={`secondary`}
      content={CareersData[intl.locale]}
    />
    <div className={`main-page-wrapper careers`}>
      <Container>
        <h2 className={`mt-5 mb-4`}>{CareersData[intl.locale].text}</h2>
        <Row>
          {CareersData[intl.locale].openings.map((openings, i) => {
              return (
                <div key={i} className={`col-md-12`}>
                  <div className={`openings-category`}>
                    <h3>{openings.category}</h3>
                    {openings.jobs.map((item, j) => {
                      return (
                        <Row key={j}>
                          <Col md={12}>
                            <SimpleRowCard content={item} />
                          </Col>
                        </Row>
                      )
                    })}
                  </div>
                </div>
              )
          })}
          </Row>

        <ClientsTicker content={TickerData[intl.locale]} />
      </Container>
    </div>
  </LayoutSecondary>
)

export default injectIntl(CareersIndexPage)
